export default {
    splash: {
//		video: 'loop1.mp4',
		video: 'surround-video-1080-slim.mp4',
		text: `Good evening
<p>This is the voice of Enigma
<p>In the next hour we will take you with us into another world, into the world of music, spirit and meditation
<p>Turn off the light, take a deep breath and relax
<p>Start to move slowly, very slowly
<p>Let the rhythm be your guiding light
`, 						// text | html; текст с призывом к выбору
		routes: [

			{
		    	to: 'root',				// id следующего узла
				text: 'let\'s begin',		// text; надпись на кнопке для перехода по этому пути
//				video: 'https://vimeo.com/90509568',					// path | url; видео для воспроизведения при переходе
				video: '4k-loop1.mov',
			},
/*
			{
		    	to: 'splash',				
				text: 'surround video 230Mb',	
				video: 'surround-video-1080.mp4',
			},

			{
		    	to: 'splash',				
				text: 'surround video 33Mb',	
				video: 'surround-video-1080-slim.mp4',
			},

			{
		    	to: 'splash',				
				text: 'surround test 12345',	
				video: 'surround-test-12345.mp4',
			},
			{
		    	to: 'splash',				
				text: 'surround test MOV 12345',	
				video: 'all-chans-ok.mov',
			},
			{
		    	to: 'splash',				
				text: 'surround test MP4 12345',	
				video: 'all-chans-ok.mp4',
			},
			{
		    	to: 'splash',
				text: 'surround test 12345 MP4 BROS',
				video: 'surround-test-mp4-bros.mp4',
			},
			{
		    	to: 'splash',
				text: 'the getaway movie (MP4)',
				video: 'seg-4-a1.mp4',
			},
			{
		    	to: 'splash',
				text: '71-qaac27.mp4',
				video: '71-qaac27.mp4',
			},
			{
		    	to: 'splash',
				text: '71-qaac27.webm',
				video: '71-qaac27.webm',
			},
			{
		    	to: 'splash',
				text: '71-flac-to-opus.mp4',
				video: '71-flac-to-opus.mp4',
			},
			{
		    	to: 'splash',
				text: '71-flac-to-opus.webm',
				video: '71-flac-to-opus.webm',
			},
*/
		],
    },

	root: {										// text; для внутреннего использования и удобства в создании дерева; должен быть уникальным
		video: 'loop2.mp4',							// path | url залупленного видео
		image: '',							// path | url картинки до загрузки лупа; если видео не указано - будет статик картинка
		text: ``, 						// text | html; текст с призывом к выбору
//		position: ...,						// controls, narration position
		routes: [
    		{
    		    to: 'splash',
    			text: 'back',
    			video: 'short2.mp4',
    		}, {
		    	to: 'node2',				// id следующего узла
				text: 'go to node #2 (long)',		// text; надпись на кнопке для перехода по этому пути
//				action-ru: ''				// вариант для i18n
				video: 'https://vimeo.com/90509568',					// path | url; видео для воспроизведения при переходе
			}, {
		    	to: 'node3',
				text: 'go to node #3',
				video: 'short3.mp4',
			},
		],
	},

	node2: {
    	video: 'loop3.mp4',
    	text: 'node 2 narration',
    	routes: [
    		{
    		    to: 'root',
    			text: 'go to root',
    			video: 'short4.mp4',
    		}, {
    		    to: 'node3',
    			text: 'go to node #3',
    			video: 'short5.mp4',
    		}, {
    		    to: 'node4',
    			text: 'go to node #4',
    			video: 'short6.mp4',
    		},
    	],
    },

    node3: {
    	video: 'loop4.mp4',
    	text: 'node 3 narration',
    	routes: [
    		{
    		    to: 'root',
    			text: 'go to root',
    			video: 'short9.mp4',
    		}, {
    		    to: 'node2',
    			text: 'go to node #2',
    			video: 'short10.mp4',
    		}, {
    		    to: 'node4',
    			text: 'go to node #4',
    			video: 'short11.mp4',
    		},
    	],
    },

    node4: {
    	video: 'loop5.mp4',
    	text: 'node 4 narration',
    	routes: [
    		{
    		    to: 'root',
    			text: 'go to root',
    			video: 'short12.mp4',
    		}, {
    		    to: 'node2',
    			text: 'go to node #2',
    			video: 'short13.mp4',
    		}, {
    		    to: 'node3',
    			text: 'go to node #3',
    			video: 'short3.mp4',
    		},
    	],
	},
}

