import React, { useRef, useEffect, useLayoutEffect, useState } from 'react'
import config from './config'
//import config from './config_short'

import Xarrow from "react-xarrows";

const boxStyle = {border: "grey solid 2px", borderRadius: "10px", padding: "5px"};

function SimpleExample() {
    const box1Ref = useRef(null);
    return (
        <div style={{display: "flex", justifyContent: "space-evenly", width: "100%"}}>
            <div ref={box1Ref} style={boxStyle}>hey</div>
            <p id="elem2" style={boxStyle}>hey2</p>
            <Xarrow
                start={box1Ref} //can be react ref
                end="elem2" //or an id
            />
        </div>
    );
}

const Box = (props) => {
	return (
		<div style={boxStyle}>{props.id}</div>
	)
}


// дерево делает ок; непонятно, как поступать с параллельными связями и со связями взад; оставил это пока этот код
const proccessed = {}
const plain = []

const getNodes = (nodeId) => {
    
//	if (proccessed[nodeId])
  //  	return {}
	
    let out = {
    	name: nodeId,
    	text: config[nodeId].text,
//    	children: []
    }
//console.log(node)
//proccessed[nodeId] = 1
//console.log(proccessed[nodeId] === undefined);return

	if (proccessed[nodeId] === undefined) {
		proccessed[nodeId] = 1
		if (config[nodeId].routes?.length) {
			out.children = []
			for(const route of config[nodeId].routes) {
//				if (proccessed[route.to] === undefined) 
					out.children.push(getNodes(route.to))
			plain.push('rect rgb('+(Math. floor(Math. random() * 55) + 200)+', '+(Math. floor(Math. random() * 55) + 200)+', '+(Math. floor(Math. random() * 55) + 200)+')')
					plain.push(`${nodeId} -->> ${route.to} : ${route.text}`)
			plain.push('end')
//	        	else
//	        		out.back = getNodes(route.to)
			}
		}
	}
	else
		proccessed[nodeId]++

	return out
}

const tree = getNodes('splash')
console.log('tree', tree)
console.log('proccessed', proccessed)
console.log('plain', plain.join('\n'))


/*
const data = {
  	nodes: [
//  	{ id: "Harry" }
	],
  	links: [
//    	{ source: "Harry", target: "Sally" },
  	],
}

const fillTree = () => {
	for(const node in config) {
		data.nodes.push({
			key: node,
			text: config[node].text.substring(0, 10)
		})
	}
	for(const node in config)
		for(const route of config[node].routes) {
			data.links.push({
				from: node,
				to: route.to,
				key: route.text
			})
	}
}

fillTree()
console.log(data)
*/

const secondsToTime = seconds => {
	var date = new Date(0);
	date.setSeconds(seconds);
	return date.toISOString().substring(14, 19);
}

export default ({ props }) => {
	const progressRef = useRef(null)
	const [width, setWidth] = useState(0)

	const s = props.state

	useEffect(() => {
	    setWidth(progressRef.current ? progressRef.current.offsetWidth : 0)
	}, [props.state]);

	useEffect(() => {
console.log(plain)
	    
	}, []);

	const handleSkip = () => {
		props.setState({
			...props.state,
			narration: true,
			loop: true,
			muted: true,
			url: props.state.currentNode.video,
		})
	}

	return (
		<div className='tree-panel'>

{/*
			{plain.map((route, idx) =>
console.log(route, idx)
			)}
*/}      	
			<div onClick={()=>{window.location.href='/'}} className='close'>&times;</div>
    		<div ref={progressRef} className='progress'>
    			<div className='loaded' style={{width: s.loaded * width+'px'}}></div>
    			<div className='played' style={{width: s.played * width+'px'}}></div>
    			<span style={{width:width+'px'}}>{secondsToTime(s.duration*s.played)} / {secondsToTime(s.duration)}</span>
    		</div>
			<div className='text'>
	    	    {s.loop && <>looping <b>{s.currentNode.id}</b></>}
    		    {!s.loop && <span onClick={handleSkip}>skip to <b>{s.currentNode.id}</b></span>}<br />
	    		<b>{s.url}</b><br />
    			volume: <b>{s.volume}</b>
    			{s.muted && <> (muted)</>}
    			<br />

    			{/*looped: <b>{s.loop+0}</b><br />*/}
    	    </div>
		</div>
	)
}