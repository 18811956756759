import React, { useRef, useEffect, useLayoutEffect, useState } from 'react'

const secondsToTime = seconds => {
	var date = new Date(0);
	date.setSeconds(seconds);
	return date.toISOString().substring(14, 19);
}

export default ({ props }) => {
	const progressRef = useRef(null)
	const [width, setWidth] = useState(0)

	const s = props.state

	useEffect(() => {
	    setWidth(progressRef.current ? progressRef.current.offsetWidth : 0)
	}, [props.state]);

	const handleSkip = () => {
		props.setState({
			...props.state,
			narration: true,
			loop: true,
			muted: true,
			url: props.state.currentNode.video,
		})
	}

	return (
		<div className='dev-panel'>
			<div onClick={()=>{window.location.href='/'}} className='close'>&times;</div>
    		<div ref={progressRef} className='progress'>
    			<div className='loaded' style={{width: s.loaded * width+'px'}}></div>
    			<div className='played' style={{width: s.played * width+'px'}}></div>
    			<span style={{width:width+'px'}}>{secondsToTime(s.duration*s.played)} / {secondsToTime(s.duration)}</span>
    		</div>
			<div className='text'>
	    	    {s.loop && <>looping <b>{s.currentNode.id}</b></>}
    		    {!s.loop && <span onClick={handleSkip}>skip to <b>{s.currentNode.id}</b></span>}<br />
	    		<b>{s.url}</b><br />
    			volume: <b>{s.volume}</b>
    			{s.muted && <> (muted)</>}
    			<br />

    			{/*looped: <b>{s.loop+0}</b><br />*/}
    	    </div>
		</div>
	)
}