import React, { createContext, useContext, useState, useRef } from 'react'
import routes from './routes'

const InflightControls = ({ props }) => {

//console.log('props.state in controls', props.state)
//console.log(routes)

  	const renderGoButton = (route, idx) => {
    	return (
      		<button key={idx} onClick={() => {
/*
      			props.setState({
      				...props.state,
      				loaded: 0,
      				played: 0,
      				url: null,
      			})
*/
      			props.setState({
      				...props.state,
      				url: route.video,
      				playing: true,
      				muted: false,
      				loop: false,
      				narration: false,      				
      				currentNode: routes.getNode(route.to),
      			})
      		}}>
        		{route.text}
      		</button>
    	)
  	}
	const targetRoutes = props.state.currentNode.routes

	return (
      	<div className='controls'>
      		<button>back</button>
		</div>
)}

export default InflightControls