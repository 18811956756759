import React, { useState, useRef, useEffect, useContext } from 'react'
import { findDOMNode } from 'react-dom'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import routes from './routes'

function Player({ props }) {
//	const [context, setContext] = useContext(State)


    const ref = useRef()
//console.log(props.state)
//		<LoopControls />
/*
*/
    return (
    <>

        <div className='player-wrapper'>

			<ReactPlayer
				ref={ref}
				className='react-player'
          		width='100%'
          		height='100%'          		

                url={props.state.url}
                playing={props.state.playing}
                loop={props.state.loop}
                volume={props.state.volume}
                muted={props.state.muted}

/*
                onReady={() => console.log('onReady')}
                onStart={() => console.log('onStart')}
                onBuffer={() => console.log('onBuffer')}
                onError={e => console.log('onError', e)}

                onPlay={() => console.log('onPlay')}
                onPause={() => console.log('onPause')}
*/                
                
                onEnded={
                	(e) => {
//console.log('onEnded')
                	props.setState({
						...props.state,
						narration: true,
						loop: true,
						muted: true,
						url: props.state.currentNode.video,
					})}
//                	props.state.handleEnded
                }
                onProgress={(e) => props.setState({
                	...props.state,
                	played: e.played,
                	loaded: e.loaded,
                })}
                onDuration={(e) => props.setState({
                	...props.state,
                	duration: e,
                })}
          	/>
	  	</div>


    </>
  );
}

export default Player;
