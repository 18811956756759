import './App.css';
import React, { createContext, useEffect, useContext, useState, useRef, useCallback } from 'react'
import FadeIn from 'react-fade-in'
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import routes from './routes'
//import LoopScreen from './LoopScreen'

//import Splash from './Splash'
import Dev from './Dev'
import Tree from './Tree'
import Player from './Player'
import LoopControls from './LoopControls'
import Narration from './Narration'
import InflightControls from './InflightControls'

//import { State } from './StateContext';

const isDev = window.location.search == '?dev' 

function App() {

	const wrapperRef = useRef()
  	const [ inflight, setInflight ] = useState(false)
  	const [ state, setState ] = useState({
    	url: null,
    	playing: true,
    	volume: 0.8,
    	muted: true,	// silence at start up
    	played: 0,
    	loaded: 0,
    	duration: 0,
    	loop: true,		// looped intro
    	fullscreen: false,
    	currentNode: routes.getNode('splash'),
    	wrapperRef,
    	narration: true,	// true = narration screen | false = playing the route
  	})

	useEffect(() => {
		setState({
			...state,
			// first run
			url: state.currentNode.video,
		})
	}, [])

	const handleKeyPress = useCallback(event => {
//console.log(`Key pressed: ${event.key}`);
        // active in-flight or dev mode only
    	if (!state.narration || isDev) {
	    	if (event.key == ' ')
				setState({
					...state,
					playing: !state.playing,
				})
    		if (event.key == 'ArrowUp')
				handleVolumeUp()
    		if (event.key == 'ArrowDown')
				handleVolumeDown()
	    	if (event.key == 'm')
				setState({ ...state, muted: !state.muted })
		}

    	if (event.key == 'f')
			handleToggleFullscreen()

	}, [state]);
  
	useEffect(() => {
	    document.addEventListener('keydown', handleKeyPress);
    	return () => {
      		document.removeEventListener('keydown', handleKeyPress);
    	}
  	}, [handleKeyPress]);
  
    const handleMouseMove = useCallback(event => {
//console.log(`mouse: ${event}`);
/*
    	if (!state.narration) {
    		setInflight(true)
    		const timer = setTimeout(() => {
    			setInflight(false)
    		}, 2000)
		}
*/
    }, [state]);

	useEffect(() => {
	    document.addEventListener('mousemove', handleMouseMove);
    	return () => {
      		document.removeEventListener('mousemove', handleMouseMove);
    	}
  	}, [handleMouseMove]);

    const handleToggleFullscreen = () => {screenfull.toggle(findDOMNode(wrapperRef.current), {navigationUI: 'hide'})}
    const handleVolumeUp = () => {
   		if (state.volume < 1)
			setState({
				...state,
				volume: Math.round((state.volume + .1) * 10) / 10,
				muted: false,
	})}
    const handleVolumeDown = () => {
   		if (state.volume > 0)
			setState({
				...state,
				volume: Math.round((state.volume - .1) * 10) / 10,
				muted: false,
	})}


//    <State.Provider value={{ state, setState }}>
    return (
    	<div className="App" ref={wrapperRef}>
      		<div className='overlay'>

      		    {isDev && <Dev props={{state, setState }} />}

{/*      		    {isDev && <Tree props={{state, setState }} />}*/}

				{state.narration &&
					<FadeIn delay={0} transitionDuration={200} >
						<Narration props={{state, setState}} />
						<LoopControls props={{state, setState}} />
		        	</FadeIn>
				}

				{inflight && !state.narration && <InflightControls props={{state, setState}} />}

				{screenfull.isEnabled &&
					<div onClick={handleToggleFullscreen} title='Toggle Fullscreen' className='fullscreen'>
						{!screenfull.isFullscreen && <BiFullscreen />}
						{screenfull.isFullscreen && <BiExitFullscreen />}
					</div>}

			</div>
			<Player props={{state, setState}} />

	    </div>
  );
}

export default App;
